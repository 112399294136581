import { Order } from '@/types/order';
import { api } from './api';

const baseUrl = '/orders';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<Order[], void>({
      query: () => `${baseUrl}`,
      providesTags: ['Order'],
    }),
    getOrderById: builder.query<Order, { orderId: string }>({
      query: ({ orderId }) => `${baseUrl}/${orderId}`,
      providesTags: ['Order'],
    }),
    deleteOrder: builder.mutation<Order, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `${baseUrl}/${orderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order'],
    }),
  }),
  overrideExisting: false,
});

export const orderEndpoints = ordersApi.endpoints;
export const {
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useDeleteOrderMutation,
} = ordersApi;
