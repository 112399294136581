import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { Outlet, useNavigate } from 'react-router-dom';

type TabValue = 'kategorie' | 'atrybuty';

export const ProductOptions = () => {
  const navigate = useNavigate();
  const handleTabClick = (value: TabValue) =>
    navigate(`/opcje-produktow/${value}`);

  return (
    <div className="flex flex-col">
      <Tabs defaultValue="kategorie" className="w-full">
        <TabsList className="px-10 pt-10 flex">
          <TabsTrigger
            value="kategorie"
            onClick={() => handleTabClick('kategorie')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Kategorie
          </TabsTrigger>
          <TabsTrigger
            value="atrybuty"
            onClick={() => handleTabClick('atrybuty')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Atrybuty
          </TabsTrigger>
        </TabsList>
        <TabsContent value="kategorie">
          <Outlet />
        </TabsContent>
        <TabsContent value="atrybuty">
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  );
};
