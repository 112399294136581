import { useGetAllParetnCategoriesQuery } from '@/api/categories';
import { NotFound } from '../../NotFound';
import { AddEditCategories } from './AddEditCategories';
import { DataTable } from './DataTable';
import { columns } from './columns';

export const Categories = () => {
  const { data, isLoading } = useGetAllParetnCategoriesQuery();
  const categories = data?.categories?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista kategorii
        </h1>
        {!!categories && <AddEditCategories light />}
      </div>

      {!!categories ? (
        <DataTable isParent={true} columns={columns} data={data.categories} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnej kategorii"
          element={<AddEditCategories />}
        />
      )}
    </>
  );
};
