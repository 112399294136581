import calendar from '@/assets/calendar.svg';
import home from '@/assets/home.svg';
import { MenuListType } from '@/types/menuListType';

export const menuList: MenuListType[] = [
  {
    name: 'Zamówienia',
    href: '/zamowienia',
    icon: home,
  },
  {
    name: 'Produkty',
    href: '/produkty',
    icon: home,
  },
  {
    name: 'Opcje produktów',
    href: '/opcje-produktow/kategorie',
    icon: calendar,
  },
  {
    name: 'Ustawienia',
    href: '/ustawienia/mailing',
    icon: calendar,
  },
];
