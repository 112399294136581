import { Product } from '@/types/product';
import { File, Plus } from 'lucide-react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

type Props = {
  light?: boolean;
  type?: 'update';
  product?: Product;
};

export const Buttons = ({ light, type, product }: Props): ReactElement => {
  const isUpdate = type === 'update';

  return (
    <>
      {isUpdate ? (
        <Link to={`/produkty/edytuj/${product?.id}`}>
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        </Link>
      ) : (
        <Link to="/produkty/dodaj">
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj produkt</p>
          </Button>
        </Link>
      )}
    </>
  );
};
