import {
  useGetAllCategoriesQuery,
  useGetAllParetnCategoriesQuery,
} from '@/api/categories';
import { DataTable } from './DataTable';
import { CategoriesProvider } from './categories-context';
import { columns } from './columns';
import { Category } from '@/types/category';

type Props = {
  setCategories: (categories: Category['id'][]) => void;
};

export const Categories = ({ setCategories }: Props) => {
  const { data } = useGetAllParetnCategoriesQuery();
  const categories = !!data?.categories?.length;
  const { data: allCategoriesData } = useGetAllCategoriesQuery();

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-xl font-semibold leading-loose mb-4">Kategorie</h1>
      {!!categories && (
        <CategoriesProvider
          setCategories={setCategories}
          allCategories={allCategoriesData?.categories ?? []}
        >
          <DataTable isParent={true} columns={columns} data={data.categories} />
        </CategoriesProvider>
      )}
    </div>
  );
};
