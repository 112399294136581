import {
  useCreateVariantMutation,
  useDeleteVariantImageMutation,
  useUpdateVariantMutation,
  useUploadVariantImageMutation,
} from '@/api/variants';
import { Textarea } from '@/components/ui/textarea';
import { Attribute } from '@/types/attribute';
import { Product } from '@/types/product';
import { Variant, File as VariantFile } from '@/types/variant';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Files, Plus, Trash } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../../ui/button';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { Input } from '../../ui/input';
import { PickAttributes } from './PickAttributes';

const Schema = yup.object({
  name: yup
    .string()
    .max(60, {
      message: 'Nazwa może zawierać maksymalnie 60 znaków',
    })
    .required('Nazwa jest wymagana'),
  description: yup.string(),
  price: yup.number().required('Cena jest wymagana'),
  quantity: yup.number().required('Ilość jest wymagana'),
  attributesIds: yup.array().optional(),
});

type Props = {
  light?: boolean;
  type?: 'update';
  variant?: Variant;
  product: Product;
};

export const AddEditVariant = ({
  light,
  type,
  variant,
  product,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [attributes, setAttributes] = useState<Attribute['id'][]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [variantImages, setVariantImages] = useState<VariantFile[]>([]);
  const [createVariant] = useCreateVariantMutation();
  const [updateVariant] = useUpdateVariantMutation();
  const [uploadVariantImage] = useUploadVariantImageMutation();
  const [deleteVariantImage] = useDeleteVariantImageMutation();
  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: variant?.name,
      description: variant?.description || '',
      price: variant?.price,
      quantity: variant?.quantity,
    },
  });

  useEffect(() => {
    if (!!variant?.files?.length) {
      setVariantImages(variant.files);
    }
  }, [variant]);

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) {
        return;
      }

      if (images.length > 0) {
        await Promise.all(
          images.map(async (image) => {
            await uploadVariantImage({
              variantId: variant!.id,
              image: image,
            });
          }),
        );
      }

      if (type === 'update') {
        await updateVariant({
          name: values.name,
          description: values.description,
          variantId: variant!.id,
          price: values.price,
          attributesIds: attributes,
        });
      } else {
        await createVariant({
          productId: product.id,
          name: values.name,
          description: values.description,
          price: values.price,
          quantity: values.quantity,
          attributesIds: attributes,
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = async (index: number) => {
    try {
      if (variant && images[index]) {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImage = async (imageId: VariantFile['id']) => {
    try {
      if (!!variant) {
        deleteVariantImage({
          variantId: variant.id,
          imageId: imageId,
        });

        setVariantImages((prev) => {
          return prev.filter((el) => el.id !== imageId);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj wariant</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="p-8 w-132 max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj wariant' : 'Dodaj wariant'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                return form.handleSubmit(onSubmit)(e);
              }}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Dedykowany opis wariantu</FormLabel>{' '}
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-center gap-10">
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem className="w-20">
                      <FormLabel>Cena</FormLabel>{' '}
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="quantity"
                  render={({ field }) => (
                    <FormItem className="w-20">
                      <FormLabel>Ilość</FormLabel>{' '}
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-1 gap-6">
                {product?.attributeTypes.map((attributeType) => {
                  const selectedAttribute = attributeType?.attributes?.find(
                    (attribute) =>
                      variant?.attributes?.some(
                        (variantAttribute) =>
                          variantAttribute.id === attribute.id,
                      ),
                  );

                  return (
                    !!attributeType?.attributes?.length && (
                      <FormItem
                        key={attributeType.id}
                        className="flex flex-col gap-1"
                      >
                        <FormLabel>{attributeType.name}</FormLabel>
                        <FormControl>
                          <PickAttributes
                            setAttributes={setAttributes}
                            selectedAttribute={selectedAttribute}
                            attributeType={attributeType}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  );
                })}
              </div>
              {type === 'update' && (
                <FormItem>
                  <FormControl>
                    <div>
                      <label
                        htmlFor="file-input"
                        className="flex items-center justify-center w-full py-8 bg-slate-50 rounded border border-dashed border-slate-400"
                      >
                        <div className="flex flex-col justify-center items-center gap-y-4">
                          <Files className="w-16 h-16" />
                          <p className="text-center">
                            Przeciągnij i umieść plik tutaj,
                            <br /> lub{' '}
                            <span className="font-medium underline underline-offset-4">
                              wybierz plik
                            </span>
                          </p>
                        </div>
                      </label>

                      <Input
                        type="file"
                        id="file-input"
                        accept="png,jpg,avif,webp,svg,jpeg"
                        className="border-none text-xs font-normal leading-tight file-input sr-only"
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length > 0) {
                            const filesArray = Array.from(e.target.files);
                            setImages((prevImages) => [
                              ...prevImages,
                              ...filesArray,
                            ]);
                          }
                        }}
                        multiple
                      />
                      <p className="text-sm font-medium leading-tight mt-4 mb-2">
                        {images.length > 0
                          ? `Wybrane zdjęcia do wgrania (${images.length}):`
                          : ''}
                      </p>
                      <ul className="grid grid-cols-4 gap-2 w-full mb-2">
                        {images.map((image, index) => (
                          <li key={index} className="relative group">
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Uploaded image ${index}`}
                              className="object-cover h-20 w-full rounded"
                            />
                            <Button
                              className="hidden group-hover:flex justify-center items-center size-full absolute left-0 top-0 bg-transparent hover:bg-red-500/50 duration-200"
                              onClick={() => handleDeleteImage(index)}
                              type={'button'}
                            >
                              <Trash className="w-4 h-4" />
                            </Button>
                          </li>
                        ))}
                      </ul>
                      <p className="text-sm font-medium leading-tight mt-4 mb-2">
                        {!!variantImages?.length
                          ? `Przesłane zdjęcia (${variantImages.length}):`
                          : ''}
                      </p>
                      <ul className="grid grid-cols-4 gap-2 w-full">
                        {variantImages.map(({ path, id }) => (
                          <li key={id} className="relative group">
                            <img
                              src={`https://storage.googleapis.com/dewocjonalia/${path}`}
                              className="object-cover h-20 w-full rounded"
                            />
                            <Button
                              className="hidden group-hover:flex justify-center items-center size-full absolute left-0 top-0 bg-transparent hover:bg-red-500/50 duration-200"
                              onClick={() => deleteImage(id)}
                              type={'button'}
                            >
                              <Trash className="w-4 h-4" />
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              <DialogFooter className="sm:justify-end mb-10">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz wariant
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
