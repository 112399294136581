import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Category } from '@/types/category';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { NestedRow } from './NestedRow';

interface DataTableProps<TData extends Category, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isParent: boolean;
}

export function DataTable<TData extends Category, TValue>({
  columns,
  data,
  isParent,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));
  };

  if (data.length === 0) {
    return null;
  }

  const handleRowClick = (
    rowId: string,
    event: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'INPUT') {
      toggleRowExpansion(rowId);
    }
  };

  return (
    <div className={clsx(isParent ? '' : 'ml-4 w-full')}>
      <div className={clsx('rounded-md border', !isParent && 'border-r-0')}>
        <Table>
          {isParent && (
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
          )}
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={(event) => handleRowClick(row.id, event)}
                  className="cursor-pointer"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                {expandedRows[row.id] && (
                  <NestedRow parentCategory={row.original} />
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
