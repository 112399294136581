import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Login } from './components/login/Login';
import { OrderView } from './components/orderView/OrderView';
import { OrdersView } from './components/ordersView/OrdersView';
import { AttributesView } from './components/product-options/AttributesView';
import { ProductOptions } from './components/product-options/ProductOptios';
import { Categories } from './components/product-options/categories/Categories';
import { AddEditProduct } from './components/products/AddEditProduct';
import { Products } from './components/products/Products';
import { Variants } from './components/products/variants/Variants';
import { Delivery } from './components/settings/Delivery';
import { Mailing } from './components/settings/Mailing';
import { Settings } from './components/settings/Settings';

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'zamowienia',
        element: <OrdersView />,
      },
      {
        path: 'zamowienia/:orderId',
        element: <OrderView />,
      },
      {
        path: 'produkty',
        children: [
          {
            index: true,
            element: <Products />,
          },
          {
            path: 'dodaj',
            element: <AddEditProduct />,
          },
          {
            path: 'edytuj',
            element: <AddEditProduct type="update" />,
            children: [
              {
                path: ':id',
                element: <Variants />,
              },
            ],
          },
        ],
      },
      {
        path: 'opcje-produktow',
        element: <ProductOptions />,
        children: [
          {
            path: 'kategorie',
            element: <Categories />,
          },
          {
            path: 'atrybuty',
            element: <AttributesView />,
          },
        ],
      },
      {
        path: 'ustawienia',
        element: <Settings />,
        children: [
          {
            path: 'mailing',
            element: <Mailing />,
          },
          {
            path: 'dostawa',
            element: <Delivery />,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
