import { useDeleteAttributeMutation } from '@/api/attributes';
import { Toaster } from '@/components/ui/toaster';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { toast } from '@/components/ui/use-toast';
import { Attribute } from '@/types/attribute';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../../ConfirmationModal';
import { Button } from '../../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu';
import { AddEditAttributes } from './AddEditAttributes';

const handleClick = (id: string) => {
  navigator.clipboard.writeText(id);
  toast({
    title: 'Skopiowano do schowka',
    description: 'ID Atrybutu zostało skopiowane do schowka',
    duration: 3000,
  });
};

export const columns: ColumnDef<Attribute>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      const id = row.original.id;
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div onClick={() => handleClick(id!)} className="cursor-pointer">
                {trimId(id)}
                <Toaster />
              </div>
            </TooltipTrigger>
            <TooltipContent
              onClick={() => handleClick(id!)}
              className="cursor-pointer"
            >
              <p>
                Kliknij, aby skopiować do schowka
                <br />
                ID: {id}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
  {
    accessorKey: 'value',
    header: 'Nazwa',
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteAttribute] = useDeleteAttributeMutation();
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <AddEditAttributes type="update" attribute={row.original} />
              <ConfirmationModal
                title="Usuń"
                description="Czy na pewno chcesz usunąć ten atrybut?"
                onConfirm={() =>
                  deleteAttribute({ attributeId: row.original.id! })
                }
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
