import { Attribute } from '@/types/attribute';
import { AttributeType } from '@/types/attribute-type';
import { api } from './api';

const baseUrl = '/attributes';

const attributesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<{ attributes: Attribute[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Attribute'],
    }),
    getAttributesByType: builder.query<
      { attributes: Attribute[] },
      { attributeType: string }
    >({
      query: ({ attributeType }) => `${baseUrl}/type/${attributeType}`,
      providesTags: ['Attribute'],
    }),
    createAttribute: builder.mutation<
      { attribute: Attribute },
      {
        value: Attribute['value'];
        attributeTypeId: AttributeType['id'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Attribute'],
    }),
    updateAttribute: builder.mutation<
      { attribute: Attribute },
      {
        attributeId: Attribute['id'];
        value: Attribute['value'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.attributeId}`,
        method: 'PUT',
        body: { value: body.value },
      }),
      invalidatesTags: ['Attribute'],
    }),
    deleteAttribute: builder.mutation<
      { attribute: Attribute },
      { attributeId: Attribute['id'] }
    >({
      query: ({ attributeId }) => ({
        url: `${baseUrl}/${attributeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Attribute'],
    }),
  }),
  overrideExisting: false,
});

export const attributeEndpoints = attributesApi.endpoints;
export const {
  useGetAttributesQuery,
  useGetAttributesByTypeQuery,
  useCreateAttributeMutation,
  useUpdateAttributeMutation,
  useDeleteAttributeMutation,
} = attributesApi;
