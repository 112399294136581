import { Checkbox } from '@/components/ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Category } from '@/types/category';
import { ColumnDef } from '@tanstack/react-table';
import { useCategories } from './categories-context';

export const columns: ColumnDef<Category>[] = [
  {
    id: 'select',
    cell: ({ row }) => {
      const { categories, toggleCategorySelection } = useCategories();

      const handleCheckboxClick = () => {
        const categoryId = row.original.id;
        toggleCategorySelection(categoryId);
        row.toggleSelected(!categories.includes(categoryId));
      };

      return (
        <Checkbox
          checked={categories.includes(row.original.id)}
          onCheckedChange={handleCheckboxClick}
          aria-label="Select row"
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
    cell: ({ row }) => {
      return row.original.description ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <p>{row.original.name}</p>
            </TooltipTrigger>
            <TooltipContent>
              <p>{row.original.description}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <p>{row.original.name}</p>
      );
    },
  },
];
