import { Category } from '@/types/category';
import { v4 as uuidv4 } from 'uuid';
import { api } from './api';

const baseUrl = '/categories';

const categoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query<{ categories: Category[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Category'],
    }),
    getAllParetnCategories: builder.query<{ categories: Category[] }, void>({
      query: () => `${baseUrl}/parents`,
      providesTags: ['Category'],
    }),
    getCategoriesByParentId: builder.query<
      { categories: Category[] },
      { parentId: Category['id'] }
    >({
      query: ({ parentId }) => `${baseUrl}/parent/${parentId}`,
      providesTags: ['Category'],
    }),
    createCategory: builder.mutation<
      { category: Category },
      {
        name: Category['name'];
        description?: Category['description'];
        parentId: Category['parent']['id'] | null;
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
        parentId: uuidv4(),
      }),
      invalidatesTags: ['Category'],
    }),
    updateCategory: builder.mutation<
      { category: Category },
      {
        categoryId: Category['id'];
        name: Category['name'];
        description?: Category['description'];
        parentId: Category['parent']['id'] | null;
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.categoryId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Category'],
    }),
    deleteCategory: builder.mutation<
      { category: Category },
      { categoryId: Category['id'] }
    >({
      query: ({ categoryId }) => ({
        url: `${baseUrl}/${categoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),
  }),
  overrideExisting: false,
});

export const categoryEndpoints = categoriesApi.endpoints;
export const {
  useGetAllCategoriesQuery,
  useGetAllParetnCategoriesQuery,
  useGetCategoriesByParentIdQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
