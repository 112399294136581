import {
  useCreateProductMutation,
  useGetProductByIdQuery,
  useUpdateProductMutation,
} from '@/api/product';
import { Product } from '@/types/product';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../ui/button';
import { DialogFooter } from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Attributes } from './attributes/Attributes';
import { Categories } from './categories/Categories';

const Schema = yup.object({
  name: yup
    .string()
    .max(60, 'Nazwa może zawierać maksymalnie 60 znaków')
    .required(),
  descriptionPrimary: yup.string().required(),
  descriptionSecondary: yup.string(),
});

type Props = {
  type?: 'update';
};

export const AddEditProduct = ({ type }: Props): ReactElement => {
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [attributeTypes, setAttributeTypes] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const navigate = useNavigate();
  const params = useParams();

  const isUpdate = type === 'update';

  const { data } = useGetProductByIdQuery(
    { productId: params.id! },
    {
      skip: !isUpdate || !params?.id,
    },
  );
  const product = data?.product;

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: product?.name || '',
      descriptionPrimary: product?.descriptionPrimary || '',
    },
  });

  useEffect(() => {
    if (!!product) {
      form.reset(product as unknown as yup.InferType<typeof Schema>);

      const attributeTypeIds = product?.attributeTypes.map(({ id }) => id);
      !!attributeTypeIds?.length && setAttributeTypes(attributeTypeIds);
    }
  }, [product, form.reset]);

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) {
        return;
      }

      if (type === 'update') {
        await updateProduct({
          ...values,
          productId: product?.id!,
          name: values.name ?? '',
          descriptionPrimary: values.descriptionPrimary,
          attributeTypes,
          categories,
        });
      } else {
        const result: any = await createProduct({
          ...values,
          name: values.name || '',
          descriptionPrimary: values.descriptionPrimary,
          descriptionSecondary: values.descriptionSecondary,
          attributeTypes,
          categories,
        });

        if (result['data'] as { product: Product })
          navigate(`/produkty/edytuj/${result['data'].product.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mt-12 mx-10">
      <h1 className="text-2xl font-semibold leading-loose mb-4">
        {type === 'update' ? 'Edytuj produkt' : 'Dodaj produkt'}
      </h1>
      <div className="flex space-x-2 w-full">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
            <div className="flex gap-x-10 w-full">
              <div
                className={clsx(
                  isUpdate
                    ? 'flex flex-col gap-y-6 w-full max-w-100'
                    : 'grid grid-cols-2 w-full gap-10',
                )}
              >
                <div
                  className={clsx(
                    'flex',
                    isUpdate ? 'flex-col' : 'flex-row justify-between',
                  )}
                >
                  <div className="flex flex-col gap-y-4 min-w-100 col-span-2 w-full">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Nazwa</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="descriptionPrimary"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Opis</FormLabel>{' '}
                          <FormControl>
                            <Textarea {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Categories setCategories={setCategories} />
                  </div>
                </div>
                <Attributes
                  setAttributeTypes={setAttributeTypes}
                  attributeTypes={attributeTypes}
                />
                <DialogFooter className="sm:justify-end mb-10">
                  <Button
                    type="submit"
                    className="bg-slate-900 text-white mt-4"
                  >
                    Zapisz produkt
                  </Button>
                </DialogFooter>
              </div>
              <Outlet />
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};
