import { useGetAttributesByTypeQuery } from '@/api/attributes';
import { Attribute } from '@/types/attribute';
import { AttributeType } from '@/types/attribute-type';
import { DataTable } from '../../DataTable';
import { AddEditAttributes } from './AddEditAttributes';
import { columns } from './columns';

type Props = {
  attributeType?: AttributeType;
};

export const Attributes = ({ attributeType }: Props) => {
  let attributes: Attribute[] = [];

  if (!!attributeType) {
    const { data } = useGetAttributesByTypeQuery(
      {
        attributeType: attributeType!.name,
      },
      { skip: !attributeType?.name },
    );
    attributes = !!data?.attributes?.length ? data.attributes : [];
  }

  return (
    <div className="flex flex-col w-1/2">
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Wartości atrybutów
        </h1>
        <AddEditAttributes attributeType={attributeType} light />
      </div>

      <DataTable margin columns={columns} data={attributes} />
    </div>
  );
};
