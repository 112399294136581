import { useDeleteProductMutation } from '@/api/product';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Product } from '@/types/product';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { File, MoreHorizontal, Trash } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Toaster } from '../ui/toaster';
import { toast } from '../ui/use-toast';

const handleClick = (id: string) => {
  navigator.clipboard.writeText(id);
  toast({
    title: 'Skopiowano do schowka',
    description: 'ID typu atrybutu zostało skopiowane do schowka',
    duration: 3000,
  });
};

export const columns: ColumnDef<Product>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      const id = row.original.id;
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div onClick={() => handleClick(id!)} className="cursor-pointer">
                {trimId(id)}
                <Toaster />
              </div>
            </TooltipTrigger>
            <TooltipContent
              onClick={() => handleClick(id!)}
              className="cursor-pointer"
            >
              <p className="text-black/75">
                <b className="font-medium text-black">
                  Kliknij, aby skopiować do schowka:
                </b>
                <br />
                ID: {id}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'descriptionPrimary',
    header: 'Opis',
  },
  {
    accessorKey: 'descriptionSecondary',
    header: 'Opis',
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteProduct] = useDeleteProductMutation();
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <Link to={`/produkty/edytuj/${row.original.id}`}>
                <Button variant="ghost" className="px-2 w-full justify-start">
                  <File className="w-4 h-4 mr-1" />
                  Edytuj
                </Button>
              </Link>
              <ConfirmationModal
                title="Usuń"
                description="Czy na pewno chcesz usunąć ten produkt?"
                onConfirm={() => deleteProduct({ productId: row.original.id! })}
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
