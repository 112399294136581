import { useDeleteCategoryMutation } from '@/api/categories';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Category } from '@/types/category';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../../ConfirmationModal';
import { Button } from '../../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu';
import { AddEditCategories } from './AddEditCategories';

export const columns: ColumnDef<Category>[] = [
  {
    accessorKey: 'name',
    header: 'Nazwa',
    cell: ({ row }) => {
      return row.original.description ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <p>{row.original.name}</p>
            </TooltipTrigger>
            <TooltipContent>
              <p>{row.original.description}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <p>{row.original.name}</p>
      );
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteCategory] = useDeleteCategoryMutation();
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <AddEditCategories type="create-nested" category={row.original} />
              <AddEditCategories type="update" category={row.original} />
              <ConfirmationModal
                title="Usuń"
                description="Czy na pewno chcesz usunąć tę kategorię?"
                onConfirm={() =>
                  deleteCategory({ categoryId: row.original.id! })
                }
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
