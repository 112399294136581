import { useGetAttributesByTypeQuery } from '@/api/attributes';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Attribute } from '@/types/attribute';
import { AttributeType } from '@/types/attribute-type';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';

type Props = {
  setAttributes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedAttribute?: Attribute;
  attributeType: AttributeType;
};

export const PickAttributes = ({
  setAttributes,
  selectedAttribute,
  attributeType,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [attr, setAttr] = useState<Attribute | null>(selectedAttribute ?? null);
  const { data } = useGetAttributesByTypeQuery(
    {
      attributeType: attributeType!.name,
    },
    { skip: !attributeType?.name },
  );

  const attributes = data?.attributes || [];

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between text-black"
        >
          {attr?.value ? attr.value : 'Wybierz atrybut...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder="Wyszukaj atrybut..." />
          <CommandEmpty>Nie znaleziono atrybutu</CommandEmpty>
          <CommandGroup>
            {attributes.map((attribute) => {
              return (
                <CommandItem
                  key={attribute.id}
                  defaultValue={selectedAttribute?.value}
                  onSelect={() => {
                    const attributesIds = attributes.map(({ id }) => id);
                    setAttr(attribute);
                    setAttributes((previous) => {
                      const foundToRemove = previous.find((el) =>
                        attributesIds.includes(el),
                      );

                      const updated = previous.filter(
                        (el) => el !== foundToRemove,
                      );

                      return [...updated, attribute.id];
                    });
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      attr?.value === attribute.value
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                  {attribute.value}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
