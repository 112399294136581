import { useGetAttributeTypesQuery } from '@/api/attributeTypes';
import { AttributeType } from '@/types/attribute-type';
import { NotFound } from '../../NotFound';
import { AddEditAttributeTypes } from './AddEditAttributeTypes';
import { DataTable } from './DataTable';
import { columns } from './columns';

type Props = {
  setAttributeType: (attributeType: AttributeType) => void;
};

export const AttributeTypes = ({ setAttributeType }: Props) => {
  const { data, isLoading } = useGetAttributeTypesQuery();
  const attributeTypes = data?.attributeTypes?.length;

  return (
    <div className="flex flex-col w-1/2">
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista atrybutów
        </h1>
        {!!attributeTypes && <AddEditAttributeTypes light />}
      </div>

      {!!attributeTypes ? (
        <DataTable
          setAttributeType={setAttributeType}
          columns={columns}
          data={data.attributeTypes}
        />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnego typu atrybutu"
          element={<AddEditAttributeTypes />}
        />
      )}
    </div>
  );
};
