import logOut from '@/assets/logOut.svg';
import { useLogout } from '@/hooks/useLogout';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { ReactElement } from 'react';
import { Button } from '../ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet';
import { AvatarElement } from './AvatarElement';

export const MenuUser = (): ReactElement => {
  const logout = useLogout();
  return (
    <Sheet>
      <SheetTrigger>
        <div className="flex items-center gap-x-2 p-3">
          <AvatarElement />
          <div className="flex flex-col pl-4">
            <ChevronUp className="w-3 h-3" />
            <ChevronDown className="w-3 h-3" />
          </div>
        </div>
      </SheetTrigger>
      <SheetContent className="pt-16">
        <SheetHeader>
          <SheetTitle>
            <div className="flex items-center gap-x-2 p-3">
              <AvatarElement />
            </div>
          </SheetTitle>
          <SheetDescription>
            {/* Routing */}
            <Button
              variant="ghost"
              onClick={logout}
              className="text-black text-sm font-medium leading-tight flex gap-x-2 items-center"
            >
              <img src={logOut} alt="Logout icon" />
              <p>Wyloguj</p>
            </Button>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};
