import { Category } from '@/types/category';
import { uniqBy } from 'lodash';
import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface CategoriesContextType {
  categories: string[];
  allCategories: Category[];
  toggleCategorySelection: (categoryId: string) => void;
}

const CategoriesContext = createContext<CategoriesContextType | undefined>(
  undefined,
);

export const useCategories = (): CategoriesContextType => {
  const context = useContext(CategoriesContext);
  if (!context)
    throw new Error('useCategories must be used within a CategoriesProvider');
  return context;
};

export const CategoriesProvider: FC<{
  children: ReactNode;
  allCategories: Category[];
  setCategories: (categories: Category['id'][]) => void;
}> = ({ children, allCategories, setCategories: setSelectedCategories }) => {
  const [categories, setCategories] = useState<string[]>([]);

  const toggleCategorySelection = (categoryId: string) => {
    setCategories((prevCategoriesIds) => {
      const categories = findParentCategiries(categoryId);
      const categoriesIds = categories.map(({ id }) => id);

      if (prevCategoriesIds.includes(categoryId)) {
        const categories = getChilrenCategories(categoryId);
        const categoriesIds = categories.map(({ id }) => id);

        const filteredCategories = prevCategoriesIds.filter(
          (cat) => !categoriesIds.includes(cat),
        );
        setSelectedCategories(filteredCategories);
        return filteredCategories;
      } else {
        const filteredCategories = [...prevCategoriesIds, ...categoriesIds];
        setSelectedCategories(filteredCategories);
        return filteredCategories;
      }
    });
  };

  const getChilrenCategories = (
    parentCategoryId: Category['id'],
  ): Category[] => {
    const parentCategory = allCategories.find(
      ({ id }) => id === parentCategoryId,
    );
    if (!parentCategory) return [];

    const categories = allCategories.filter(
      ({ parent }) => !!parent && parent.id === parentCategoryId,
    );

    for (let i = 0; i < categories.length; i++) {
      const children = getChilrenCategories(categories[i].id);
      return uniqBy(
        [...categories, ...children, parentCategory],
        ({ id }) => id,
      );
    }

    return [];
  };

  const findParentCategiries = (calegoryId: Category['id']) => {
    const categories: Category[] = [];

    let parentCategoryId: Category['id'] | null = calegoryId;
    do {
      let foundCategory = allCategories.find(
        ({ id }) => id === parentCategoryId,
      );

      if (!!foundCategory?.parent?.id)
        parentCategoryId = foundCategory.parent.id;
      else parentCategoryId = null;

      categories.push(foundCategory!);
    } while (!!parentCategoryId);

    return categories;
  };

  return (
    <CategoriesContext.Provider
      value={{ categories, allCategories, toggleCategorySelection }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};
