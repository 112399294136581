import { Order } from '@/types/order';
import { Calendar, CircleDot } from 'lucide-react';
import { ReactElement } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';

export const OrderAccordion = ({ order }: { order: Order }): ReactElement => {
  let statusClassName = '';
  let statusLabel = '';
  let status = order.status;

  switch (status) {
    case 'awaiting':
      statusClassName = 'border-neutral-400 text-neutral-500';
      statusLabel = 'Oczekiwanie';
      break;
    case 'paid':
      statusClassName = 'border-cyan-600 text-cyan-600';
      statusLabel = 'Opłacone';
      break;
    case 'refunded':
      statusClassName = 'border-orange-600 text-orange-600';
      statusLabel = 'Zwrócone';
      break;
    case 'preparing':
      statusClassName = 'border-yellow-500 text-yellow-500';
      statusLabel = 'Przygotowywanie';
      break;
    case 'shipped':
      statusClassName = 'border-blue-600 text-blue-600';
      statusLabel = 'Wysłane';
      break;
    case 'delivered':
      statusClassName = 'border-emerald-600 text-emerald-600';
      statusLabel = 'Dostarczone';
      break;
    case 'returned':
      statusClassName = 'border-red-600 text-red-600';
      statusLabel = 'Zwrócone';
      break;
    default:
      statusClassName = 'border-gray-400 text-gray-400';
      statusLabel = 'Nieznany status';
      break;
  }

  return (
    <>
      <Accordion type="multiple" className="w-106">
        <AccordionItem value="item-1">
          <AccordionTrigger className="text-slate-700 text-xl font-medium leading-7">
            Dane zamówienia
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-y-4">
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Status
              </p>
              <p
                className={`${statusClassName} inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1 `}
              >
                <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Data złożenia zamówienia
              </p>
              <span className="flex items-center gap-x-1">
                <Calendar className="w-4 h-4 text-slate-700" />
                <p>{new Date(order.date).toLocaleString()}</p>
              </span>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Data opłacenia zamówienia
              </p>
              <p className="text-slate-500 text-sm font-normal leading-tight">
                Brak
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Wysyłka na adres
              </p>
              <p className="text-slate-500 text-sm font-normal leading-tight">
                Brak
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Sposób płatności
              </p>
              <p className="text-slate-500 text-sm font-normal leading-tight">
                Brak
              </p>
            </div>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger className="text-slate-700 text-xl font-medium leading-7">
            Dane zamawiającego
          </AccordionTrigger>
          <AccordionContent>
            <p className="text-slate-700 text-base font-semibold leading-normal mb-3">
              Dane osobowe
            </p>
            {/* <div className="mb-4">
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Imię i nazwisko
              </p>
              <p className="text-sm font-normal leading-tight">Jan Kowalski</p>
            </div> */}
            {/* <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Adres e-mail
              </p>
              <p className="text-sm font-normal leading-tight">{order.email}</p>
            </div> */}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};
