import logo from '@/assets/logo.svg';
import { ReactElement } from 'react';
import { MenuButtons } from './MenuButtons';
import { MenuUser } from './MenuUser';

export const Menu = (): ReactElement => {
  return (
    <nav className="flex justify-between items-center w-full px-10 bg-white border-b border-slate-200">
      <div className="flex gap-x-6">
        <a href="/">
          <img src={logo} className="h-12" />
        </a>
        <MenuButtons />
      </div>
      <MenuUser />
    </nav>
  );
};
