import { AttributeType } from '@/types/attribute-type';
import { api } from './api';

const baseUrl = '/attribute-types';

const attributeTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttributeTypes: builder.query<{ attributeTypes: AttributeType[] }, void>(
      {
        query: () => `${baseUrl}`,
        providesTags: ['AttributeType'],
      },
    ),
    createAttributeType: builder.mutation<
      { attributeType: AttributeType },
      {
        name: AttributeType['name'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AttributeType'],
    }),
    updateAttributeType: builder.mutation<
      { attributeType: AttributeType },
      {
        attributeTypeId: AttributeType['id'];
        name: AttributeType['name'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.attributeTypeId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['AttributeType'],
    }),
    deleteAttributeType: builder.mutation<
      { attributeType: AttributeType },
      { attributeTypeId: AttributeType['id'] }
    >({
      query: ({ attributeTypeId }) => ({
        url: `${baseUrl}/${attributeTypeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AttributeType'],
    }),
  }),
  overrideExisting: false,
});

export const attributeTypeEndpoints = attributeTypesApi.endpoints;
export const {
  useGetAttributeTypesQuery,
  useCreateAttributeTypeMutation,
  useUpdateAttributeTypeMutation,
  useDeleteAttributeTypeMutation,
} = attributeTypesApi;
