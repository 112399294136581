import { useGetOrderByIdQuery, useGetOrdersQuery } from '@/api/orders';
import { Order } from '@/types/order';
import { ReactElement } from 'react';
import { DataTable } from '../DataTable';
import { Loading } from '../login/Loading';
import { columns } from './columns';

export const OrdersView = (): ReactElement => {
  const ordersQuery = useGetOrdersQuery();
  const orderId = ordersQuery.data?.[0]?.orderId;
  const { isLoading: isOrderLoading } = useGetOrderByIdQuery(
    { orderId: orderId ?? '' },
    { skip: !orderId },
  );
  const orders: Order[] = ordersQuery.data ?? [];

  if (ordersQuery.isLoading || isOrderLoading) return <Loading />;

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-2xl font-semibold leading-loose ml-10 mt-12 mb-7.5">
        Zamówienia
      </h1>
      <DataTable margin columns={columns} data={orders} />
    </div>
  );
};
