import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type AttributesContextType = {
  selectedAttributes: string[];
  toggleAttributeSelection: (attributeId: string) => void;
};

const AttributesContext = createContext<AttributesContextType | undefined>(
  undefined,
);

export const useAttributes = (): AttributesContextType => {
  const context = useContext(AttributesContext);
  if (!context)
    throw new Error('useAttributes must be used within an AttributesProvider');
  return context;
};

export const AttributesProvider: FC<{
  children: ReactNode;
  setAttributeTypes: (attributes: string[]) => void;
  attributeTypes: string[];
}> = ({ children, setAttributeTypes, attributeTypes }) => {
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>(
    !!attributeTypes?.length ? attributeTypes : [],
  );
  const toggleAttributeSelection = (attributeId: string) => {
    setSelectedAttributes((prevState) => {
      if (prevState.includes(attributeId)) {
        return prevState.filter((id) => id !== attributeId);
      } else {
        return [...prevState, attributeId];
      }
    });
  };

  useEffect(() => {
    setAttributeTypes(selectedAttributes);
  }, [selectedAttributes]);

  useEffect(() => {
    setAttributeTypes(attributeTypes);
    setSelectedAttributes(attributeTypes);
  }, [attributeTypes]);

  return (
    <AttributesContext.Provider
      value={{ selectedAttributes, toggleAttributeSelection }}
    >
      {children}
    </AttributesContext.Provider>
  );
};
