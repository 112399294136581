import { AttributeType } from '@/types/attribute-type';
import { useState } from 'react';
import { AttributeTypes } from './attribute-types/AttributeTypes';
import { Attributes } from './attributes/Attributes';

export const AttributesView = () => {
  const [attributeType, setAttributeType] = useState<AttributeType>();

  return (
    <div className="flex">
      <AttributeTypes setAttributeType={setAttributeType} />
      <Attributes attributeType={attributeType} />
    </div>
  );
};
