import { Attribute } from '@/types/attribute';
import { Variant } from '@/types/variant';
import { api } from './api';

const baseUrl = '/variants';

const variantsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVariantById: builder.query<{ variant: Variant }, { variantId: string }>({
      query: ({ variantId }) => `${baseUrl}/${variantId}`,
      providesTags: ['Variant'],
    }),
    getVariantsByProductId: builder.query<
      { variants: Variant[] },
      { productId: string }
    >({
      query: ({ productId }) => `${baseUrl}/product/${productId}`,
      providesTags: ['Variant'],
    }),
    createVariant: builder.mutation<
      { variant: Variant },
      {
        productId: Variant['product']['id'];
        name: Variant['name'];
        description?: Variant['description'];
        price: Variant['price'];
        quantity: Variant['quantity'];
        attributesIds?: Attribute['id'][];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Variant'],
    }),
    updateVariant: builder.mutation<
      { variant: Variant },
      {
        variantId: Variant['id'];
        name?: Variant['name'];
        description?: Variant['description'];
        price?: Variant['price'];
        quantity?: Variant['quantity'];
        attributesIds?: Attribute['id'][];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.variantId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Variant'],
    }),
    deleteVariant: builder.mutation<
      { variant: Variant },
      { variantId: Variant['id'] }
    >({
      query: ({ variantId }) => ({
        url: `${baseUrl}/${variantId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Variant'],
    }),
    uploadVariantImage: builder.mutation<
      { variant: Variant },
      {
        variantId: Variant['id'];
        image: File;
      }
    >({
      query: ({ variantId, image }) => {
        const formData = new FormData();
        formData.append('image', image);
        return {
          url: `${baseUrl}/${variantId}/images`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Variant'],
    }),
    deleteVariantImage: builder.mutation<
      { variant: Variant },
      {
        variantId: Variant['id'];
        imageId: string;
      }
    >({
      query: ({ variantId, imageId }) => ({
        url: `${baseUrl}/${variantId}/images/${imageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Variant'],
    }),
  }),
  overrideExisting: false,
});

export const variantEndpoints = variantsApi.endpoints;
export const {
  useGetVariantByIdQuery,
  useGetVariantsByProductIdQuery,
  useCreateVariantMutation,
  useUpdateVariantMutation,
  useDeleteVariantMutation,
  useUploadVariantImageMutation,
  useDeleteVariantImageMutation,
} = variantsApi;
