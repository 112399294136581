import { Order } from '@/types/order';
import { File } from 'lucide-react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

export const ProductList = ({ order }: { order: Order }): ReactElement => {
  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className="flex justify-between w-full items-center mb-4">
        <p className="text-slate-700 text-xl font-medium leading-7">
          Lista produktów
        </p>
        <Button asChild variant="secondary">
          <Link to={`.pdf`}>
            <File className="w-6 h-6 mr-1" />
            <p className="text-sm font-medium leading-normal">
              Zobacz dokument PDF
            </p>
          </Link>
        </Button>
      </div>
      <div className="grid grid-cols-6 items-center rounded-md bg-slate-100 w-full h-36">
        <p className="col-span-2 col-start-4 text-sm font-normal leading-normal">
          Kwota
        </p>
        <p className="col-span-1 text-center text-sm font-normal leading-tight">
          {order.value + ' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
        <p className="col-span-2 col-start-4 text-sm font-normal leading-normal">
          Dostawa
        </p>
        <p className="col-span-1 text-center text-sm font-normal leading-tight">
          {order.value + ' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
        <p className="col-span-2 col-start-4 text-sm font-semibold leading-normal">
          Suma
        </p>
        <p className="col-span-1 text-center text-xl font-medium leading-tight">
          {order.value + ' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
      </div>
    </div>
  );
};
