import { menuList } from '@/constants/menuList';
import { MenuListType } from '@/types/menuListType';
import { FC, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/button';

type MenuButtonProps = MenuListType;

const MenuButton: FC<MenuButtonProps> = ({ href, icon, name }) => {
  const location = useLocation();

  const isActive =
    location.pathname === href ||
    (location.pathname.startsWith(`/${href.split('/')[1]}/`) &&
      location.pathname !== `/${href.split('/')[1]}/`);

  return (
    <Link key={name} to={href} className="text">
      <Button
        key={name}
        variant="ghost"
        className={`${
          isActive ? 'bg-slate-200' : ''
        } hover:bg-slate-200 px-5 py-2.5`}
      >
        <div className="flex gap-x-1 items-center w-full">
          <img src={icon} alt={`${name} icon`} />
          <p className="text-sm font-medium leading-tight">{name}</p>
        </div>
      </Button>
    </Link>
  );
};

export const MenuButtons = (): ReactElement => {
  return (
    <div className="flex items-center">
      {menuList.map((menuItem, index) => (
        <MenuButton key={index} {...menuItem} />
      ))}
    </div>
  );
};
