import {
  useCreateAttributeTypeMutation,
  useUpdateAttributeTypeMutation,
} from '@/api/attributeTypes';
import { AttributeType } from '@/types/attribute-type';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Plus } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { Input } from '../../ui/input';

const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  description: yup.string(),
  parentId: yup.string().uuid(),
});

type Props = {
  light?: boolean;
  type?: 'update';
  attributeTypes?: AttributeType;
};

export const AddEditAttributeTypes = ({
  light,
  type,
  attributeTypes,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [createAttributeTypes] = useCreateAttributeTypeMutation();
  const [updateAttributeTypes] = useUpdateAttributeTypeMutation();

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = form;
  const onSubmit = async (values: any) => {
    try {
      if (type === 'update') {
        await updateAttributeTypes({
          ...values,
          attributeTypeId: attributeTypes?.id!,
          name: values.name,
          description: values.description,
          parentId: values.parentId,
        });
      } else {
        await createAttributeTypes({
          ...values,
          name: values.name,
          description: values.description,
          parentId: values.parentId,
        });
      }

      setOpen(false);
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj atrybut</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj atrybut' : 'Dodaj atrybut'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input {...field} defaultValue={attributeTypes?.name} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz typ atrybutu
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
