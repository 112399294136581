import { useGetAttributeTypesQuery } from '@/api/attributeTypes';
import { DataTable } from '@/components/DataTable';
import { AttributesProvider } from './attributes-context';
import { columns } from './columns';

type Props = {
  setAttributeTypes: (attributeTypes: string[]) => void;
  attributeTypes: string[];
};

export const Attributes = ({ setAttributeTypes, attributeTypes }: Props) => {
  const { data } = useGetAttributeTypesQuery();

  return (
    <div className="flex flex-col">
      <h1 className="text-xl font-semibold leading-loose mb-4">Atrybuty</h1>

      {!!data?.attributeTypes?.length && (
        <AttributesProvider
          setAttributeTypes={setAttributeTypes}
          attributeTypes={attributeTypes}
        >
          <DataTable columns={columns} data={data.attributeTypes} />
        </AttributesProvider>
      )}
    </div>
  );
};
