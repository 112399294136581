import { useCurrentUser } from '@/slices/credentials/selectors';
import { capitalize } from 'lodash';
import { ReactElement } from 'react';
import { Avatar, AvatarImage } from '../ui/avatar';

export const AvatarElement = (): ReactElement => {
  const user = useCurrentUser()!;
  return (
    <>
      <Avatar>
        {/* <AvatarImage src="https://github.com/yetiasg.png" /> */}
      </Avatar>
      {user && (
        <div className="flex flex-col text-start text-sm leading-tight">
          <h2 className="font-medium">{`${user.firstName} ${user.lastName}`}</h2>
          <p className="text-xsm font-normal">{capitalize(user.role)}</p>
        </div>
      )}
    </>
  );
};
