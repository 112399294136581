import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { Outlet, useNavigate } from 'react-router-dom';

type TabValue = 'mailing' | 'dostawa';

export const Settings = () => {
  const navigate = useNavigate();
  const handleTabClick = (value: TabValue) => navigate(`/ustawienia/${value}`);

  return (
    <div className="flex flex-col">
      <Tabs defaultValue="mailing" className="w-full">
        <TabsList className="px-10 pt-10 flex">
          <TabsTrigger
            value="mailing"
            onClick={() => handleTabClick('mailing')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Mailing
          </TabsTrigger>
          <TabsTrigger
            value="delivery"
            onClick={() => handleTabClick('dostawa')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Dostawa
          </TabsTrigger>
        </TabsList>
        <TabsContent value="mailing">
          <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
            Ustawienia mailingu
          </h1>
          <Outlet />
        </TabsContent>
        <TabsContent value="delivery">
          <h1 className="text-2xl font-semibold leading-loose px-10 pt-10">
            Ustawienia dostawy
          </h1>
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  );
};
