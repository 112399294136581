import { useGetProductByIdQuery } from '@/api/product';
import { useGetVariantsByProductIdQuery } from '@/api/variants';
import { DataTable } from '@/components/DataTable';
import { NotFound } from '@/components/NotFound';
import { useParams } from 'react-router-dom';
import { AddEditVariant } from './AddEditVariant';
import { columns } from './columns';

export const Variants = () => {
  const params = useParams();
  const { data: productData } = useGetProductByIdQuery(
    {
      productId: params?.id!,
    },
    {
      skip: !params?.id,
    },
  );

  const product = productData?.product ?? null;
  const { data, isLoading } = useGetVariantsByProductIdQuery(
    {
      productId: product?.id!,
    },
    {
      skip: !product?.id,
    },
  );
  const variants = data?.variants?.length;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold leading-loose">Warianty</h1>
        {!!variants && product && <AddEditVariant product={product} light />}
      </div>

      {!!variants ? (
        <DataTable columns={columns} data={data.variants} />
      ) : isLoading ? null : (
        product && (
          <NotFound
            description="Nie znaleziono żadnego wariantu"
            element={<AddEditVariant product={product} />}
          />
        )
      )}
    </div>
  );
};
