import { useGetOrderByIdQuery } from '@/api/orders';
import { Order } from '@/types/order';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../login/Loading';
import { OrderAccordion } from './OrderAccordion';
import { ProductList } from './ProductList';

export const OrderView = (): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>();
  const { data, isLoading } = useGetOrderByIdQuery(
    { orderId: orderId! },
    { skip: !orderId },
  );

  if (isLoading) return <Loading />;

  const order: Order | undefined = data;

  if (!order) {
    return <div>Nie znaleziono zamówienia o podanym identyfikatorze.</div>;
  }

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-slate-500 text-2xl font-semibold leading-loose ml-10 mt-12 mb-6">
        Zamówienie <span className="text-slate-900">#{order.orderId}</span>
      </h1>
      <div className="flex gap-x-10 px-10">
        <OrderAccordion order={order} />
        <ProductList order={order} />
      </div>
    </div>
  );
};
