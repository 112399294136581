import { Product } from '@/types/product';
import { api } from './api';
import { Category } from '@/types/category';
import { AttributeType } from '@/types/attribute-type';

const baseUrl = '/products';

const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<{ products: Product[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Product'],
    }),
    getProductById: builder.query<{ product: Product }, { productId: string }>({
      query: ({ productId }) => `${baseUrl}/${productId}`,
      providesTags: ['Product'],
    }),
    createProduct: builder.mutation<
      { product: Product },
      {
        name: Product['name'];
        descriptionPrimary?: Product['descriptionPrimary'];
        descriptionSecondary?: Product['descriptionSecondary'];
        imageUrl?: Product['imageUrl'];
        categories?: Category['id'][];
        attributeTypes?: AttributeType['id'][];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Product'],
    }),
    updateProduct: builder.mutation<
      { product: Product },
      {
        productId: Product['id'];
        name: Product['name'];
        descriptionPrimary?: Product['descriptionPrimary'];
        descriptionSecondary?: Product['descriptionSecondary'];
        imageUrl?: Product['imageUrl'];
        categories?: Category['id'][];
        attributeTypes?: AttributeType['id'][];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.productId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Product'],
    }),
    deleteProduct: builder.mutation<
      { product: Product },
      { productId: Product['id'] }
    >({
      query: ({ productId }) => ({
        url: `${baseUrl}/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Product'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = productsApi.endpoints;
export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productsApi;
