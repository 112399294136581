import { Checkbox } from '@/components/ui/checkbox';
import { AttributeType } from '@/types/attribute-type';
import { ColumnDef } from '@tanstack/react-table';
import { useAttributes } from './attributes-context';

export const columns: ColumnDef<AttributeType>[] = [
  {
    id: 'select',
    cell: ({ row }) => {
      const { selectedAttributes, toggleAttributeSelection } = useAttributes();

      const handleCheckboxChange = () => {
        toggleAttributeSelection(row.original.id);
      };

      return (
        <Checkbox
          checked={selectedAttributes.includes(row.original.id)}
          onCheckedChange={handleCheckboxChange}
          aria-label="Select row"
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
];
