import { useGetCategoriesByParentIdQuery } from '@/api/categories';
import { TableCellCategory, TableRow } from '@/components/ui/table';
import { Toaster } from '@/components/ui/toaster';
import { toast } from '@/components/ui/use-toast';
import { Category } from '@/types/category';
import { CornerDownRight } from 'lucide-react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { DataTable } from './DataTable';
import { columns } from './columns';

type Props = {
  parentCategory: Category;
};

export const NestedRow = ({ parentCategory }: Props) => {
  const { data } = useGetCategoriesByParentIdQuery(
    {
      parentId: parentCategory.id,
    },
    {
      skip: !parentCategory?.id,
    },
  );

  useEffect(() => {
    if (data && data.categories && !data.categories.length) {
      toast({
        title: 'Brak podkategorii',
        description: 'Brak podkategorii dla wybranej kategorii',
        duration: 3000,
      });
    }
  }, [data]);

  return data?.categories?.length ? (
    <TableRow>
      <TableCellCategory colSpan={columns.length}>
        <div className="flex">
          <CornerDownRight className="w-6 h-6 stroke-muted-foreground" />
          <DataTable
            isParent={false}
            columns={columns}
            data={data?.categories}
          />
        </div>
      </TableCellCategory>
    </TableRow>
  ) : (
    createPortal(<Toaster />, document.body)
  );
};
